<template>
  <div id="input-widget" class="r-border">
    <div class="cell" :selected="(index === selectedIndex)"
         v-for="(text, index) in numberArrLeft" :key="index">
      <button class="key keyboard-button"
              v-tap="{methods: onCellSelected, index: index}">{{ text }}
      </button>
    </div>

    <span class="input-span">&bull;</span>
    <div class="cell" :selected="(selectedIndex >= 2 && index === selectedIndex -2)"
         v-for="(text, index) in numberArrRight" :key="index + 2">
      <button class="key keyboard-button"
              v-tap="{methods: onCellSelected, index: (index + 2)}">{{ text }}
      </button>
    </div>
  </div>
</template>

<script>

// 定义输入框的UI操作逻辑
// Author: 陈哈哈 yoojiachen@gmail.com

export default {
  name: "InputWidget",
  props: ["numberArray", "mode", "selectedIndex"],
  methods: {

    onCellSelected: function (params) {
      this.$emit("oncellselected", params.index);
    }
  },
  computed: {
    numberArrLeft: function () {
      return this.numberArray.slice(0, 2);
    },
    numberArrRight: function () {
      return this.numberArray.slice(2, this.numberArray.length);
    },
  }
}
</script>

<style scoped>

#input-widget {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 40px;
  justify-content: space-between;
  background-color: #FFFFFF;;
}

#input-widget > .input-span {
  width: 10px;
  height: 100%;
  line-height: 40px;
  color: #2196F3;
}

#input-widget > .cell {
  width: 40px;
  height: 40px;
  -webkit-user-select: text;
  border: 1px solid #f2f2f2;

}

#input-widget > .cell > .key {

  color: black;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  outline: none;
}

#input-widget > .cell[selected=selected] {
  border: 1px solid #29a0dc;
}
</style>