<template>
    <div class="main">
        <van-loading color="#1989fa" size="48px"></van-loading>
    </div>
</template>

<script>
import localCache from '@/utils/localCache'
import {openIdLogin} from '@/api/login-api'
import { mapState, mapGetters, mapMutations } from 'vuex'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        if (this.loginState) {
            this.goToHome();
            return;
        }

        let wxOpenId = (this.openId != null) ? this.openId : localCache.get("openId")

        if (wxOpenId == null) {
            this.goToHome();
            return;
        }

        (async () => {
            let login = await (await openIdLogin(wxOpenId)).data;

            if (login.code !== 200) {
                this.goToHome();
                return;
            }

            let account = login.data;

            if (account.accType === "EMPLOYEE") {
                this.setLoginFlag(true)
                this.setAccountType(this.ACCOUNT_TYPE_EMPLOYEE)
                this.setEmployee(account.employee)
            } else if ((account.accType === "VISITOR")&&(account.visitor.type !== "black")) {
                this.setLoginFlag(true)
                this.setAccountType(this.ACCOUNT_TYPE_VISITOR)
                this.setVisitor(account.visitor)

                let setting = await getVisitorSettings();
                
                this.printDebug("setting = ", setting);
                
                let result = this.verifyVisitorInfo(account.visitor, setting);

                if (!result) {
                    this.$toast.success("请完善信息")
                    this.goToAccountDetail();
                    return
                }
            }

            this.goToHome();
        })();
    },

    computed: {
        ...mapGetters(['loginState']),
        ...mapState(['openId', 'accountType', 'employee', 'visitor'])
    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setVisitor', 'setEmployee']),
        
        goToHome() {
            this.$router.push({ name: "home", params: {source: "accountsync"} });
        },

        goToAccountDetail() {
            this.$router.push({name: "accountdetail", params: {source: "accountsync"}});
        }
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

</style>
