<template>
    <div class="manage">
        <van-popup v-model="showVisitorPickerPopup" position="bottom">
            <van-picker show-toolbar :columns="visitorPickerColumns"
                @confirm="onVisitorPickerConfirm" @cancel="showVisitorPickerPopup = false"/>
        </van-popup>

        <van-dialog class="dialog" v-model="showDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
			<van-form ref="visitorFromRef" @submit="submitAdd">
                <div class="dialog-header">
                    添加访客信息
                </div>

                <div v-for="(item, index) in visitorFormLabel" :key="index">
                    <van-field v-if="item.show === true" v-model="form[item.prop]" :required="item.required" :rules="item.rule"
                            :name="item.label" :label="item.label" :placeholder="'请输入 ' + item.label" colon
                            @click="visitorFormFieldClick(item)">
                    </van-field>
                </div>
                
                <van-row class="dialog-footer">
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="button" @click="cancelAdd()">取消</van-button>
					</van-col>
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="submit">确认</van-button>
					</van-col>
				</van-row>
            </van-form>
		</van-dialog>
        
        <div class="area">
            <van-form v-for="(item, index) in peoples" :key="index">
                <van-row>
                    <van-col span="2">
                        <van-checkbox style="margin-left:10px;margin-top:10px" v-model="item.checked" checked-color="#ee0a24"/>
                    </van-col>
                    <van-col span="21">
                        <div>
                            <van-field v-model="item.tel" readonly label-width="70px" label="手机号码:"/>
                            <van-field v-model="item.infos" readonly label-width="70px"  label="员工信息:"/>
                        </div>
                    </van-col>
                    <van-col span="1">
                    </van-col>
                </van-row>
            </van-form>
        </div>
        
        <div class="bottom">
            <van-tabbar style="height: 40px; margin-top:20px" fixed placeholder safe-area-inset-bottom>
                <van-tabbar-item @click="addPeople()" style="color: red; font-size: 16px !important;">
                    新    增
                </van-tabbar-item>

                <van-tabbar-item @click="deletePeople()" style="color: red; font-size: 16px !important;">
                    删  除
                </van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {addCompanyVisitor} from '@/api/visitor-api'
import {
    deleteUsualEmployee, getUsualEmployee,
    addUsualVisitor, deleteUsualVisitor, getUsualVisitor, updateUsualVisitor
} from '@/api/usual-api'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
            getUsualEmployee().then(({data}) => {
                this.peoples = data.data == null ? [] : data.data

                this.peoples.forEach((item, index) => {
                    this.peoples[index].tel = this.showHidePhoneNo(this.peoples[index].tel)
                    this.peoples[index].infos = item.name + " | " + item.departmentName
                })

                this.printDebug("peoples => ", this.peoples)
            }).catch(_ => {
                // this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        } else {
            getUsualVisitor().then(({data}) => {
                this.peoples = data.data == null ? [] : data.data

                this.peoples.forEach((item, index) => {
                    this.peoples[index].infos = item.name + " | " + item.company
                })

                this.printDebug("peoples => ", this.peoples)
            }).catch(_ => {
                // this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        }
    },

    data() {
        return {
            showDialog: false,
            
            peoples: [],

            form: {
                name: null,
                tel: null,
                company: null,
                tag: null,
                ic: null,
                email: null
            },

            employeeFormLabel: [
                {
                    prop: 'name',
                    label: '姓名'
                },
                {
                    prop: 'tel',
                    label: '手机号码'
                },
                {
                    prop: 'department',
                    label: '所在部门'
                }
            ],
        }
    },

    computed: {
        ...mapState(['accountType', 'visitor', 'employee', 'searchEmployee'])
    },

    methods: {
        visitorFormFieldClick(item) {
            this.visitorFieldValue = item;

            if (item.prop === "cert_type") {
                document.activeElement.blur()

                this.visitorPickerColumns = ["护照", "身份证"]

                this.showVisitorPickerPopup = true
            }
        },

        onVisitorPickerConfirm(value) {
            this.onInviteVisitorPickerConfirm(value, this.form);
        },

        addPeople() {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                this.$router.push({name: "searchemployee", params: {'source': 'usualmanage'}})
            } else {
                (async () => {
                    let setting = await getVisitorSettings();

                    this.printDebug("setting = ", setting);

                    if (setting != null) {
                        this.setInviteVisitorLabel(setting);
                    }
                    
                    //
                    this.form = {}

                    this.showDialog = true

                    //加上判断，第一次 dialog 弹出，form 表单还没有初始化。
                    if (this.$refs.visitorFromRef != null) {
                        this.$refs.visitorFromRef.resetValidation();
                    }
                })();
            }
        },

        cancelAdd() {
            this.form = {}
            this.showDialog = false
        },

        submitAdd() {
            let visitor = {};
            
            (async () => {
                let telRegex = /^1[3456789]\d{9}$/;
                
                let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

                if ((this.form.tel !== null)&&(!telRegex.test(this.form.tel))) {
                    this.form.tel = "888********";
                }

                if ((this.form.email !== null)&&(!emailRegex.test(this.form.email))) {
                    this.form.email = null;
                }

                if ((this.form.name.trim() === "")||(this.form.company.trim() === "")) {
                    this.$toast.fail("请输入合法的姓名和公司")
                    return
                }
                
                let data = (await addCompanyVisitor(this.form)).data;

                this.printDebug("login data => ", data)
                
                if (data.code !== 200) {
                    this.$toast.fail("添加访客失败");
                    return
                }

                visitor = data.data;
                visitor.infos = visitor.name + " | " + visitor.company;

                let old_length = this.peoples.length;
                this.peoples = this.peoples.filter(record => record.id !== visitor.id);

                if (this.peoples.length === old_length) {
                    this.printDebug("no exist")

                    addUsualVisitor(visitor.code).then(({data}) => {
                        this.printDebug("usualVisitor data => ", data)
                        this.peoples.unshift(visitor)
                        this.showDialog = false

                        if (visitor.exist === "EXIST") {
                            this.$toast.success("访客已存在")
                        }
                    }).catch(_ => {
                        this.$toast.fail(this.NETWORK_ERROR_MSG)
                    })
                } else {
                    this.printDebug("exist")

                    updateUsualVisitor(visitor.code).then(({data}) => {
                        this.printDebug("updateUsualVisitor data => ", data)
                        this.peoples.unshift(visitor)
                        this.showDialog = false

                        if (visitor.exist === "EXIST") {
                            this.$toast.success("访客已存在")
                        }
                    }).catch(_ => {
                        this.$toast.fail(this.NETWORK_ERROR_MSG)
                    })
                }
            })();
        },

        deletePeople() {
            let codes = []

            this.peoples.forEach((item) => {
                if (item.checked) {
                    codes.push(item.code)
                }
            })

            this.printDebug("codes => ", codes)

            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                deleteUsualEmployee(codes).then(({data}) => {
                    if (data.code === 200) {
                        this.peoples = this.peoples.filter(item => codes.indexOf(item.code) === -1)
                        this.$toast.success("删除成功")
                    } else {
                        this.$toast.fail(this.SERVER_ERROR_MSG)
                    }
                }).catch(_ => {
                    this.$toast.fail(this.NETWORK_ERROR_MSG)
                })
            } else {
                deleteUsualVisitor(codes).then(({data}) => {
                    if (data.code === 200) {
                        this.peoples = this.peoples.filter(item => codes.indexOf(item.code) === -1)
                        this.$toast.success("删除成功")
                    } else {
                        this.$toast.fail(this.SERVER_ERROR_MSG)
                    }
                }).catch(_ => {
                    this.$toast.fail(this.NETWORK_ERROR_MSG)
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>

.dialog {
    .dialog-header {
        display: flex;
        justify-content: center;
        color: green;
    }

    .dialog-footer {
        margin-bottom: 10px;
        margin-top: 20px;
    }
}

.manage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;

    .area {
        flex: 1 0 auto;

        .van-form {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: 80px;

            margin: 10px 10px 0px 10px;

            border-radius: 15px;
            border: 0.5px solid rgb(81, 126, 230);
            box-shadow: 0 0 15px #606266;

            .van-cell {
                padding: 0px 0px 0px 10px;
            }

            div {
                margin: 0px;
                padding: 0px;
            }
        }

        /deep/ .van-field__label {
            // color: #67C23A;
            font-weight: 550;
        }
    }

    .bottom {
        flex: 0 0 auto;
    }
}

</style>
