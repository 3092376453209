<template>
    <div class="main">
        <!-- 短信&微信公众号 路由界面 -->
        <van-loading v-if="!errorFlag" color="#1989fa" size="48px">加载中...</van-loading>

        <div v-else style="font-size: 20px; font-weight: 550">
            {{ errorMsg }}
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getRouteInfoByKey } from '@/api/route-api'

export default {
    data() {
        return {
            errorFlag: false,
            errorMsg: "链接已经过期 或者 无效链接"
        }
    },

    mounted() {
        // //http://192.168.168.105:8080/#/ai/9527?age=41&name=daiyunseng
        // console.log("window.location.href = ", window.location.href)

        // //{age: '41', name: 'daiyunseng'}
        // console.log("this.$route.query => ", this.$route.query)

        this.printDebug("route param => ", this.$route.params);
        
        let key = this.$route.params.key;
        
        (async () => {
            let routeInfo = (await getRouteInfoByKey(key)).data

            if ((routeInfo.code !== 200) ||
                ((routeInfo.data.apply == null)&&(routeInfo.data.record == null))) {
                // this.$toast.fail("链接已经过期 或者 无效链接")
                this.errorFlag = true;
                return
            }

            console.log("routeInfo => ", routeInfo)

            //模拟登录
            if (routeInfo.data.visitor != null) {
                this.setLoginFlag(true)
                this.setAccountType(this.ACCOUNT_TYPE_VISITOR)
                this.setVisitor(routeInfo.data.visitor)
                // this.$toast.success("访客登录成功")
            } else if (routeInfo.data.employee != null) {
                this.setLoginFlag(true)
                this.setAccountType(this.ACCOUNT_TYPE_EMPLOYEE)
                this.setEmployee(routeInfo.data.employee)
                // this.$toast.success("员工登录成功")
            } else {
                // this.$toast.fail("链接已经过期 或者 无效链接");
                this.errorFlag = true;
                return
            }

            //要查看的数据
            if (routeInfo.data.record != null) {
                this.goToEnterDetail(routeInfo.data.record)
            } else if (routeInfo.data.apply != null) {
                let apply = routeInfo.data.apply;

                switch (apply.state) {
                    case "PASS":
                    case "REJECT":
                        this.goToFinishDetail(apply)
                        break
                    case "CANCEL":
                        this.goToProcessDetail(apply)
                        break
                    case "PROCESS":
                    case "NONE":
                        if ((routeInfo.data.employee != null)
                            &&(routeInfo.data.employee.id === apply.currentEmployeeId)) {
                            this.goToApprove(apply)
                        } else {
                            this.goToProcessDetail(apply)
                        }
                        break
                }
            } else {
                this.errorFlag = true;
            }
        })()
    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setVisitor', 'setEmployee']),
        
        goToEnterDetail(record) {
            this.$router.push({ name: "recorddetail", 
                                params: {source : null, 
                                            type : this.RECORD_OPS_DETAIL, 
                                            active: this.RECORD_TAB_ENTER, 
                                            data : record} })
        },

        goToFinishDetail(apply) {
            this.$router.push({ name: "recorddetail", 
                                params: {source : null, 
                                            type : this.RECORD_OPS_DETAIL, 
                                            active: this.RECORD_TAB_FINISH, 
                                            data : apply} })
        },

        goToProcessDetail(apply) {
            this.$router.push({ name: "recorddetail", 
                                params: {source : null, 
                                            type : this.RECORD_OPS_DETAIL, 
                                            active: this.RECORD_TAB_PROCESS, 
                                            data : apply} })
        },

        goToApprove(apply) {
            this.$router.push({ name: "approve", 
                                params: {
                                    source: null, 
                                    type: this.RECORD_OPS_APPROVE, 
                                    active: this.RECORD_TAB_PROCESS, 
                                    data: apply} })

        }
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
    
</style>
