import http from "./config/http-configs";

function getSettingsData(targetList, typeList) {
    return http.post(
        `/mobile/setting/list?targetList=${targetList}&typeList=${typeList}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    );
}

function getSettingsState(targetList, typeList) {
    return http.post(
        `/mobile/setting/list/state?targetList=${targetList}&typeList=${typeList}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    );
}

export const getBasicSettings = async () => {
    let targetList = ["MOBILE"];
    let typeList = ["SYSTEM_NAME", "COMPANY_LOGO", "HOME_WELCOME"];
    
    let settings = (await getSettingsData(targetList, typeList)).data
    
    if (settings.code !== 200) {
        return null;
    }

    let sets = {}

    settings.data.forEach(element => {
        if (element.state === "VALID") {
            switch(element.type) {
                case "HOME_WELCOME":
                    sets.homeWelcome = element.content
                    break;
                case "COMPANY_LOGO":
                    sets.companyLogo = element.content
                    break;
                case "SYSTEM_NAME":
                    sets.systemName = element.content
                    break;
            }
        }
    })
    
    return sets;
}

export const getVisitorNotice = async () => {
    let targetList = ["ALL"];
    let typeList = ["VISITOR_NOTICE"];
    
    let settings = (await getSettingsData(targetList, typeList)).data

    if (settings.code !== 200) {
        return null;
    }

    let sets = {}

    settings.data.forEach(element => {
        if ((element.type === "VISITOR_NOTICE")&&(element.state === "VALID")) {
            sets.visitorNotice = element.content
            if (element.readFlag != null) {
                sets.visitorNtcReadFlag = element.readFlag.split('|').includes("mobile")
            }
        }
    })

    return sets;
}

export const getAgreementState = async () => {
    let targetList = ["ALL"];
    let typeList = ["USER_AGREEMENT", "SECRET_AGREEMENT"];

    let settings = (await getSettingsState(targetList, typeList)).data

    if (settings.code !== 200) {
        return null;
    }

    let sets = {}

    settings.data.forEach(element => {
        if ((element.type === "USER_AGREEMENT")&&(element.state === "VALID")) {
            sets.userAgreementState = true
        } else if ((element.type === "SECRET_AGREEMENT")&&(element.state === "VALID")) {
            sets.secretAgreementState = true
        }
    })

    return sets;
}

export const getAgreementContent = async () => {
    let targetList = ["ALL"];
    let typeList = ["USER_AGREEMENT", "SECRET_AGREEMENT"];

    let settings = (await getSettingsData(targetList, typeList)).data

    if (settings.code !== 200) {
        return null;
    }

    let sets = {}

    settings.data.forEach(element => {
        if ((element.type === "USER_AGREEMENT")&&(element.state === "VALID")) {
            sets.userAgreement = element.content
            if (element.readFlag != null) {
                sets.userAgrReadFlag = element.readFlag.split('|').includes("mobile")
            }
            sets.userAgreementState = true
        } else if ((element.type === "SECRET_AGREEMENT")&&(element.state === "VALID")) {
            sets.secretAgreement = element.content
            if (element.readFlag != null) {
                sets.secretAgrReadFlag = element.readFlag.split('|').includes("mobile")
            }
            sets.secretAgreementState = true
        }
    })

    return sets;
}

export const getVisitorSettings = async () => {
    let targetList = ["VISITORINFO"];
    let typeList = ["CER_TYPE"];

    let settings = (await getSettingsData(targetList, typeList)).data

    if ((settings === null) ||(settings.code !== 200)
        || (settings.data.length !== 1) || (settings.data[0].state !== 'VALID')) {
        return null;
    }
    
    return JSON.parse(settings.data[0].content);
}
