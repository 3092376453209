<template>
    <div>
        <Header class="header"></Header>
        <div v-if="loginState === true">
            <Content class="content"></Content>
            <Function class="function"></Function>
        </div>
    </div>
</template>

<script>
import Header from './Header.vue'
import Content from './Content.vue'
import Function from './Function.vue'
import { getBasicSettings, getAgreementState } from '@/api/setting-api'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    mounted() {
        let source = (this.$route.params != null) ? this.$route.params.source : null;

        this.printDebug("home source => ", source);
        
        if (this.loginState) {
            this.setPanelNumber();
        } else if (source !== "accountsync") {
            this.goToSyncAccount();
            return
        }
        
        if (this.basicSettingInit == false) {
            getBasicSettings().then((data) => {
                this.printDebug("basic setting data => ", data)
                this.setBasicSettings(data)
            }).catch(_ => {
                this.printDebug("get basic setting data error!!!!!")
            })
        }

        if (this.agreementStateInit == false) {
            getAgreementState().then((data) => {
                this.printDebug("agreement state setting data => ", data)
                this.setAgreementState(data)
            }).catch(_ => {
                this.printDebug("get agreement state setting data error!!!!!")
            })
        }
    },
    
    components: {
        Header,
        Content,
        Function
    },

    computed: {
        ...mapGetters(['loginState']),
        ...mapState(['basicSettingInit', 'agreementStateInit'])
    },
    
    methods: {
        ...mapActions(['setPanelNumber']),
        ...mapMutations(['setBasicSettings', 'setAgreementState']),

        goToSyncAccount() {
            this.$router.push({ name: "accountsync" })
        }
    }
}

</script>

<style lang="less" scoped>
</style>
