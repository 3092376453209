import http from "./config/http-configs";

// export const visitorLogin = (visitor) => {
//     return http.post(
//         '/mobile/visitor/login',
//         visitor,
//         {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',  //解决cors头问题
//                 // 'Access-Control-Allow-Credentials':'true', //解决session问题
//                 // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
//                 'Content-Type': 'application/json'
//             },
//             // withCredentials : true
//         }
//     )
// }

/*
{
    "id":16,
    "telNum":"13916936549",
    "carNum":"沪A9975",
    "icNum":"340406198303253617",
    "tagNum":"军人身份证",
    "name":"代云僧",
    "email":"daiyunseng@163.com",
    "address":"上海浦东",
    "company":"上海千柳",
    "comments":"备注信息"
}

 */
export const visitorModify = (data) => {
    return http.post(
        '/mobile/visitor/modify',
        data,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const deleteVisitor = () => {
    return http.get('/mobile/visitor/delete')
}

export const getVisitorById = () => {
    return http.get('/mobile/visitor/info')
}

export const getVisitorByCode = (visitorCode) => {
    return http.get(`/mobile/visitor/list/${visitorCode}`)
}

export const getVisitorByBatch = (visitorCodes) => {
    return http.post(
        `/mobile/visitor/list?visitorCodes=${visitorCodes}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const addCompanyVisitor = (visitor) => {
    return http.post(
        '/mobile/visitor/company/add',
        visitor,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const checkEmailUpdate = (email) => {
    return http.post(
        `/mobile/visitor/check/email?email=${email}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
