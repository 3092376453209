<template>
  <div>
    <MixedKeyboard :callbacks="callbacks"></MixedKeyboard>
  </div>
</template>

<script>
import MixedKeyboard from "@/components/carkeyboard/MixedKeyboard.vue";

export default {
  mounted() {
    this.callbacks.onCommitClick = (number) => {
      this.onConfirm(number)
    }
      this.callbacks.onBackClick = () => {
        this.onCancel()
      }
  },

  components: {
    MixedKeyboard
  },

  props: ["onConfirm", "onCancel"],


  data() {
    return {
      callbacks: {
        // 输入数据的回调
        onChanged: function (number) {
          console.log("当前车牌输入 => ", number)

        },

        // 用户点击返回按钮的回调
        onBackClick: function () {
          console.log("用户点击返回的回调")
          this.showCarKeyboardPopup = false
        },

        // 用户点击确认按钮的回调
        // number: 返回车牌；isCompleted: 是否完成输入
        onCommitClick: function (number, isCompleted) {
          console.log("用户点击[确认]的回调:number => ", number)
          console.log("用户点击[确认]的回调:isCompleted => ", isCompleted)
        }
      }
    }
  }
}
</script>

<style>

</style>