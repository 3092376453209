<template>
    <div>
        <div class="record">
            <van-cell title="行程记录" is-link @click="clickVisited()"/>
        </div>
        <van-row class="content">
            <van-col span="1">
            </van-col>

            <van-col span="7" class="content-van">
                <h3 style="color:gray" @click="clickProcess()">{{ process }}</h3>
                <p>审批中</p>
            </van-col>

            <van-col span="7" class="content-van">
                <h3 style="color:gray" @click="clickApproved()">{{ finish }}</h3>
                <p>已审批</p>
            </van-col>

            <van-col span="7" class="content-van">
                <h3 style="color:gray" @click="clickVisited()">{{ record }}</h3>
                <p>已到访</p>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
        }
    },

    computed: {
        ...mapState(['accountType', 'process', 'finish', 'record'])
    },

    methods: {
        clickProcess() {
            if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
                this.$router.push({ name: "employeerecords", params: {active : this.RECORD_TAB_PROCESS} })
            } else {
                this.$router.push({ name: "visitorrecords", params: {active : this.RECORD_TAB_PROCESS} })
            }
        },

        clickApproved() {
            if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
                this.$router.push({ name: "employeerecords", params: {active : this.RECORD_TAB_FINISH} })
            } else {
                this.$router.push({ name: "visitorrecords", params: {active : this.RECORD_TAB_FINISH}  })
            }
        },

        clickVisited() {
            if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
                this.$router.push({ name: "employeerecords", params: {active : this.RECORD_TAB_ENTER} })
            } else {
                this.$router.push({ name: "visitorrecords", params: {active : this.RECORD_TAB_ENTER}  })
            }
        }
    }
}
</script>

<style lang="less" scoped>

.record {
    margin-top: 10px;

    .van-cell__title {
        font-size: 20px !important;
        font-weight: 700;
    }
}

.content-van {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>
