export default {
    install(vue) {
        vue.prototype.VERIFY_CODE_ERROR_MSG = "验证码错误"
        vue.prototype.SERVER_ERROR_MSG = "服务器异常,请联系IT运营人员,错误码-"
        vue.prototype.NETWORK_ERROR_MSG = "网络发生故障，请稍后再试，并请联系网络管理人员"

        vue.prototype.ACCOUNT_TYPE_VISITOR = "visitor"
        vue.prototype.ACCOUNT_TYPE_EMPLOYEE = "employee"

        vue.prototype.BUSINESS_TYPE_VISITOR = "VISITOR"

        vue.prototype.APPLY_TYPE_BOOK = "BOOK_APPLY"
        vue.prototype.APPLY_TYPE_INVITE = "INVITE_APPLY"

        vue.prototype.RECORD_TAB_PROCESS = "process"
        vue.prototype.RECORD_TAB_FINISH = "finish"
        vue.prototype.RECORD_TAB_ENTER = "enter"

        vue.prototype.RECORD_OPS_DETAIL = "detail"
        vue.prototype.RECORD_OPS_CANCEL = "cancel"
        vue.prototype.RECORD_OPS_APPROVE = "approve"
        vue.prototype.RECORD_OPS_TRANSFER = "transfer"
        vue.prototype.RECORD_OPS_CHECKOUT = "checkout"

        vue.prototype.showHidePhoneNo = function(phoneNo) {
            if (phoneNo == null || phoneNo.length < 11) {
                return phoneNo
            }
            
            return phoneNo.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
        }

        vue.prototype.printDebug = function(fromat, ...args) {
            //开启debug
            let debug = 1

            if (debug == 0) {
                return
            }
            
            //打印调试日志
            console.log(fromat, ...args)
        }

        //button的防抖，对于表单提交不起作用，因为click在submit事件前触发。
        vue.directive('preventReClick', {
            inserted(el, binding) {
                el.addEventListener('click', () => {
                    if (!el.disabled) {
                        el.disabled = true
                        setTimeout(() => {
                            el.disabled = false
                        }, binding.value || 3000)
                    }
                })
            }
        })
    }//end install
}
