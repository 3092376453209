<template>
    <div>
        <van-cell class="title" title="功能列表"/>

        <div class="content">
            <div style="margin-left: 15px;"/>

            <div>
                <van-grid>
                    <van-grid-item :icon="require('../assets/apply.png')" :text="applyTitle" @click="apply()"/>
                    <van-grid-item :icon="require('../assets/person.png')" :text="usualTitle" @click="usualPerson()"/>
                    <van-grid-item v-if="this.userAgreementState" :icon="require('../assets/private-info.png')" text="用户协议" @click="agreement('user')"/>
                    <van-grid-item v-if="this.secretAgreementState" :icon="require('../assets/private-info.png')" text="隐私协议" @click="agreement('secret')"/>
                    <van-grid-item :icon="require('../assets/logout.png')" text="退出登录" @click="logout()"/>
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script>
import { accountLogout } from '@/api/login-api'
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
        }
    },

    computed: {
        ...mapState(['openId', 'accountType', 'visitor', 'employee', 'userAgreementState', 'secretAgreementState']),

        applyTitle() {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                return "访客预约"
            } else {
                return "员工邀约"
            }
        },

        usualTitle() {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                return "常访员工"
            } else {
                return "常邀访客"
            }
        }
    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setVisitor', 'setEmployee', 'clearPanelNumber']),
        
        apply() {
            this.$router.push({ name : "apply", params:{'source' : 'home'}})
        },
        
        usualPerson() {
            this.$router.push({ name : "usualmanage", params:{'source':'home'} })
        },

        agreement(type) {
            this.$router.push({ name: "useragreement", params: {source: "home", type: type} })
        },

        logout() {
            (async () => {
                let logout = {};

                logout.openId = this.openId

                if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                    logout.accountId = this.visitor.id;
                    logout.accountType = "VISITOR";
                } else {
                    logout.accountId = this.employee.id;
                    logout.accountType = "EMPLOYEE";
                }

                let data = await (await accountLogout(logout)).data;

                this.printDebug("logout req = ", logout);
                this.printDebug("logout res = ", data);

                this.setLoginFlag(null)
                this.setAccountType(null)
                this.setVisitor(null)
                this.setEmployee(null)
                this.clearPanelNumber()
                
                this.$toast.success("退出成功")
            })();
        }
    },
}
</script>

<style lang="less" scoped>

.title {
    .van-cell__title {
        font-size: 20px !important;
        font-weight: 700;
    }
}

.content {
    display: flex;
    justify-content: flex-start;

    .van-grid-item {
        /deep/.van-grid-item__icon {
            font-size: 70px;
        }

        /deep/.van-grid-item__text {
            font-size: 14px;
            color: black;
        }
    }
}

</style>
