<template>
    <div class="main">
        <van-loading color="#1989fa" size="48px"></van-loading>
    </div>
</template>

<script>
import localCache from '@/utils/localCache'
import { mapState, mapMutations } from 'vuex'
import { getAppId, getOpenId } from '@/api/wx-official-api'

export default {
    mounted() {
        if ((this.openId == null)&&(localCache.get("openId") == null)) {
            let wx_code = this.getUrlParam("code"); // 截取url中的code
            let state = this.getUrlParam("state");

            this.printDebug("wx_code => ", wx_code)
            this.printDebug("state => ", state)
            
            if (!wx_code) {
                let appid = null;

                (async () => {
                    appid = (await getAppId()).data.data

                    this.printDebug("appid = ", appid)

                    this.printDebug("window.location.href => ", window.location.href)
                    
                    let redirect = encodeURIComponent(window.location.href); //重定向回来的地址

                    //成功重定向后地址栏中将会带有code，没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来。
                    let url = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                                `appid=${appid}` + `&redirect_uri=${redirect}` +
                                "&response_type=code&scope=snsapi_base&state=wx_offical#wechat_redirect";
                    
                    this.printDebug("jump href => ", url)
                                
                    window.location.href = url
                })()
            } else {
                (async () => {
                    let openId = (await getOpenId(wx_code, state)).data.data
                    this.printDebug("openId => ", openId)
                    this.setOpenId(openId)
                    localCache.set("openId", openId)

                    this.goToSyncAccount();
                })()
            }
        } else {
            this.setOpenId(localCache.get("openId"))
            
            this.goToSyncAccount();
        }
    },
    
    computed: {
        ...mapState(['openId'])
    },

    methods: {
        ...mapMutations(['setOpenId']),

        getUrlParam: function (name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            
            var r = window.location.search.substr(1).match(reg);

            if (r != null) 
                return unescape(r[2]);
            
            return null;
        },

        goToSyncAccount() {
            this.$router.push({ name: "accountsync" })
        }
    }
}

</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

</style>
