<template>
    <div>
        <van-dialog class="dialog" v-model="showDialog"
				:show-confirm-button="false" :closeOnClickOverlay="true">
			<van-search v-model="searchValue" shape="round" show-action background="rgb(222, 231, 248)" 
                        placeholder="请输入 员工姓名">
                <template #action>
                    <div plain round type="primary" style="font-size: 16px;" @click="search">搜索</div>
                </template>
            </van-search>
            
            <div class="content" v-if="searchListState" >
                <div class="area" v-for="(item, index) in searchList" :key="index">
                    <div class="area-item" @click="searchSelect(item)">
                        {{item.name}} | {{item.tel}} | {{item.departmentName}}
                    </div>
                </div>
            </div>

            <div style="height: 250px;" v-else>
                <van-empty description="没有更多内容" />
            </div>

            <van-row class="bottom">
                <van-col offset="1" span="22">
                    <van-button size="small" round block type="info" @click="cancelDialog()">返回</van-button>
                </van-col>
            </van-row>
		</van-dialog>
        
        <RecordDetail></RecordDetail>
        
        <van-form class="approve-form">
            <div class="title">
                申 批 信 息
            </div>

            <div>
                <van-radio-group class="approve-result" v-model="result">
                    <van-radio name="pass" checked-color="#ee0a24" shape="square">通过</van-radio>
                    <van-radio name="reject" checked-color="#ee0a24" shape="square">拒绝</van-radio>
                </van-radio-group>
            </div>
            
            <div class="approve-comments">
                <van-field v-model="comments" placeholder="请输入 审核意见" type="textarea" rows="2" autosize maxlength="32" show-word-limit/>
            </div>

            <div class="approve-copies">
                <div class="copies-title">
                    <van-image round style="width:25px; height: 25px; background: #efefef; margin-right: 5px;" 
                            :src="require('../../assets/plus.png')" @click="addCopies()"/>
                    点击添加抄送人
                </div>

                <div v-if="copiesState" class="copies-list">
                    <van-badge v-for="(item, index) in copies" :key="index" 
                                style="margin-right: 15px;margin-bottom: 15px;">
                        <van-tag size="large" plain type="warning">
                            {{ item.name }} | {{item.departmentName }}
                        </van-tag>
                        <template #content>
                            <van-icon name="cross" class="badge-icon" @click="deleteCopies(item)"/>
                        </template>
                    </van-badge>
                </div>

                <div v-else class="copies-empty">
                    没有添加抄送人
                </div>
            </div>
            
            <div v-if="showGoToSourceButton">
                <van-row>
                    <van-col span="1"></van-col>

                    <van-col span="10">
                        <van-button size="small" round block style="background: #efefef;" @click="goToSource()">返回</van-button>
                    </van-col>

                    <van-col span="2"></van-col>
                    
                    <van-col span="10">
                        <van-button size="small" round block type="primary" :loading=isLoading @click="approveSubmit()">提交</van-button>
                    </van-col>

                    <van-col span="1"></van-col>
                </van-row>
            </div>

            <div v-else>
                <van-row>
                    <van-col span="4"></van-col>
                    
                    <van-col span="16">
                        <van-button size="small" round block type="primary" :loading=isLoading @click="approveSubmit()">提交</van-button>
                    </van-col>

                    <van-col span="4"></van-col>
                </van-row>
            </div>

            <div class="footer"></div>
        </van-form>
    </div>
</template>

<script>
import RecordDetail from "./RecordDetail"

import { mapState } from 'vuex'
import { approveApply } from '@/api/apply-api'
import { getSearchEmployee } from '@/api/employee-api'

export default {
    components: {
        RecordDetail
    },

    mounted() {
        this.printDebug("[checkout]$route.params = ", this.$route.params)

        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.active = this.$route.params.active
        this.apply = this.$route.params.data
    },

    computed: {
        ...mapState(['accountType', 'visitor', 'employee']),

        searchListState() {
            if (this.searchList.length > 0) {
                return true
            }

            return false
        },

        copiesState() {
            if (this.copies.length > 0) {
                return true
            }

            return false
        },

        showGoToSourceButton() {
            if (this.source != null) {
                return true
            }

            return false
        }
    },

    data() {
        return {
            isLoading: false,

            //传递过来的参数
            source : null,
            type: null,
            active: null,
            apply: null,

            //搜索相关
            showDialog: false,
            searchValue: null,
            searchList: [],
            
            //审批信息
            result: "pass",
            comments: "",
            copies: [
            ]
        }
    },

    methods: {
        goToSource() {
            this.$router.push({ name: this.source, 
                                params: {
                                    source: 'approve', 
                                    active: this.active} })
        },

        addCopies() {
            this.searchValue = null
            this.searchList = []
            this.showDialog = true
        },

        deleteCopies(item) {
            this.copies = this.copies.filter(copy => copy.id !== item.id)
        },

        cancelDialog() {
            this.showDialog = false
        },

        search() {
            if (this.searchValue === "") {
                this.searchList = []
                return
            }
            
            let employee = {}

            employee.name = this.searchValue

            getSearchEmployee(employee).then(({data}) => {
                if (data.code === 200) {
                    this.searchList = data.data
                } else {
                    this.$toast.fail(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                // console.log("error =>", error)
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })

            // this.searchList.unshift({"name":"james", "tel":"156", "departmentName":"中间件"})
        },

        searchSelect(item) {
            this.copies = this.copies.filter(copy => copy.id !== item.id)
            this.copies.unshift(item)

            this.showDialog = false
        },

        approveSubmit() {
            let approve = {}
            
            //审批识别信息
            approve.applyId = this.apply.id
            approve.totalSteps = this.apply.totalSteps
            approve.currentStep = this.apply.currentStep
            approve.employeeId = this.apply.currentEmployeeId
            
            //审核结果
            if (this.result === "pass") {
                approve.result = "PASS"
            } else {
                approve.result = "REJECT"
            }

            //审核意见
            approve.comments = this.comments

            //审核抄送
            let str = ""

            this.copies.forEach((item) => {
                str += str == "" ? item.code : '|' + item.code
            })

            approve.makeCopy = str

            //
            this.printDebug("approve submit = ", approve)

            this.isLoading = true

            //提交审核结果
            approveApply(approve).then(({data}) => {
                this.printDebug("approve result => ", data)
                
                if (data.code === 200) {
                    this.$router.push({ name: "operateresult", 
                        params: {source: this.source, type : this.type, active: this.active} })
                } else if (data.code === 730) {
                    this.isLoading = false
                    this.$toast.fail("您已经完成审批")
                } else {
                    this.isLoading = false
                    this.$toast.fail(this.NETWORK_ERROR_MSG)
                }
            }).catch((_) => {
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.dialog {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        min-height: 240px;
        // background: #efefef;

        margin-top: 10px;
        margin-bottom: 10px;
        
        .area {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 15px 10px 0px 10px;

            // border-radius: 15px;
            // border:0.5px solid green;
            box-shadow: 0 0 15px #606266;

            .area-item {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    
    .bottom {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.approve-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 30px 10px 10px 10px;

    background-color: white;
    
    border-radius: 15px;
    border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;
    
    div {
        margin: 0px;
        padding: 0px;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-top: 10px;
        margin-bottom: 20px;
        
        font-size: 18px !important;
        font-weight: 550;
    }

    .footer {
        margin-bottom: 20px;
    }

    // /deep/.van-field__label {
    //     color: red;
    //     font-weight: 550;
    // }
    
    .approve-result {
        display: flex;
        justify-content: space-evenly;
    }

    .approve-comments {
        margin: 20px 10px 0px 10px;

        border-radius: 5px;
        border:0.5px solid rgb(81, 126, 230);
        box-shadow: 0 0 15px #606266;

        .van-cell {
            // /deep/.van-field__label {
            //     padding: 0px 0px 0px 10px;
            //     margin: 0px 0px 0px 10px;
            // }

            /deep/.van-field__value {
                padding: 0px 2px 0px 0px;
                margin: 0px 2px 0px 0px;
            }
        }
    }

    .approve-copies {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 10px;

        .copies-title {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 10px;
        }

        .copies-list {
            display: flex;
            flex-flow:row wrap;

            margin-left: 20px;
        }

        .copies-empty {
            display: flex;
            justify-content: center;

            color: gray;
            // font-weight: 600;
        }
    }
}

.van-tag {
    height: 32px;
}

</style>
