import http from "./config/http-configs";

// export const employeeLogin = (employee) => {
//     return http.post(
//         '/mobile/employee/login',
//         employee,
//         {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',  //解决cors头问题
//                 // 'Access-Control-Allow-Credentials':'true', //解决session问题
//                 // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
//                 'Content-Type': 'application/json'
//             },
//             // withCredentials : true
//         }
//     )
// }

export const employeeUpdate = (employee) => {
    return http.post(
        '/mobile/employee/update',
        employee,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getEmployeeById = () => {
    return http.get('/mobile/employee/info')
}

export const getEmployeeByCode = (employeeCode) => {
    return http.get(`/mobile/employee/info/${employeeCode}`)
}

export const getEmployeeByBatch = (employeeCodes) => {
    return http.post(
        `/mobile/employee/info/batch?employeeCodes=${employeeCodes}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getSearchEmployee = (employee) => {
    return http.post(
        '/mobile/employee/search',
        employee,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
