<template>
    <div>
        <RecordDetail></RecordDetail>
        
        <van-form class="transfer-form">
            <div class="title">
                转 签 信 息
            </div>
            
            <van-search v-model="searchValue" shape="round" show-action background="rgb(222, 231, 248)" 
                    placeholder="请输入 转签人姓名" @search="search">
                <template #action>
                    <div style="margin: 0px 5px 0px 5px; font-size: 16px;"
                        plain round type="primary" @click="search">搜索</div>
                </template>
            </van-search>
            
            <div class="content" v-if="searchListState" >
                <van-radio-group class="area" v-model="transferId">
                    <van-radio class="area-item" :name="item.id" v-for="(item, index) in searchList" :key="index">
                        {{item.name}} | {{item.tel}} | {{item.departmentName}}
                    </van-radio>
                </van-radio-group>
            </div>
            
            <div v-else class="content" style="align-items: center; line-height:140px; color:gray">
                没有更多内容
            </div>

            <div class="comments">
                <van-field v-model="comments" placeholder="添加 转签备注" type="textarea" rows="2" autosize maxlength="32" show-word-limit/>
            </div>
            
            <van-row class="footer">
                <van-col span="1"></van-col>

                <van-col span="10">
                    <van-button size="small" round block style="background: #efefef;" @click="goToSource()">返回</van-button>
                </van-col>

                <van-col span="2"></van-col>
                
                <van-col span="10">
                    <van-button size="small" round block type="primary" :loading=isLoading @click="transferSubmit()">提交</van-button>
                </van-col>

                <van-col span="1"></van-col>
            </van-row>
		</van-form>
    </div>
</template>

<script>
import RecordDetail from "./RecordDetail"
import { mapState } from 'vuex'
import { getSearchEmployee } from '@/api/employee-api'
import { transferApply } from "@/api/apply-api"

export default {
    components: {
        RecordDetail
    },
    
    mounted() {
        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.active = this.$route.params.active
        this.apply = this.$route.params.data
    },

    data() {
        return {
            isLoading: false,

            searchValue: null,
            searchList: [],

            transferId: null,
            comments: null
        }
    },

    computed: {
        ...mapState(['employee']),

        searchListState() {
            if (this.searchList.length > 0) {
                return true
            }

            return false
        }
    },

    methods: {
        goToSource() {
            this.$router.push({ name: this.source, 
                                params: {
                                    source: 'transfer',
                                    type: this.type,
                                    active: this.active} })
        },

        search() {
            if (this.searchValue === "") {
                this.searchList = []
                return
            }
            
            let employee = {}

            employee.name = this.searchValue

            getSearchEmployee(employee).then(({data}) => {
                if (data.code === 200) {
                    this.searchList = data.data
                } else {
                    this.$toast.fail(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                console.log("error =>", error)
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        },

        transferSubmit() {
            if (this.transferId == null) {
                this.$toast.fail("请选择转签员工")
                return
            }

            let transfer = {
                applyId: this.apply.id,
                beginDate: this.apply.beginDate,
                endDate: this.apply.endDate,
                fromEmployeeId: this.employee.id,
                toEmployeeId: this.transferId,
                comments: this.comments
            }

            if (transfer.toEmployeeId === this.employee.id) {
                this.$toast.fail("不能转签给自己")
                return
            }

            this.printDebug("transfer submit => ", transfer)

            this.isLoading = true

            transferApply(transfer).then(({data}) => {
                this.printDebug("transfer result => ", transfer)

                if (data.code === 200) {
                    // this.$toast.success("转签成功")
                    // this.goToSource()
                    this.$router.push({ name: "operateresult", 
                        params: {source: this.source, type : this.type, active: this.active} })
                }

                this.isLoading = false
            }).catch(_ => {
                this.isLoading = false
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        }
    }
}
</script>

<style lang="less" scoped>

.transfer-form {
    margin: 20px 10px 20px 10px;

    background-color: white;
    
    border-radius: 15px;
    // border:0.5px solid #efefef;
    border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    div {
        margin: 0px;
        padding: 0px;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-top: 10px;
        margin-bottom: 10px;
        
        font-size: 18px !important;
        font-weight: 550;
    }

    .footer {
        margin-bottom: 20px;
    }

    .van-search {
        height: 40px;
        
        .van-cell {
            line-height: 30px;
            
            /deep/.van-field__left-icon {
                margin-left: 6px;
            }

            /deep/.van-field__body {
                margin-right: 6px;
            }
        }
    }
    
    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        min-height: 100px;
        background: #efefef;

        margin-top: 10px;
        margin-bottom: 10px;
        
        .area {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            margin: 10px 0px 10px 0px;
            
            .area-item {
                // border-radius: 15px;
                box-shadow: 0 0 15px #606266;

                background: white;

                margin: 10px 10px 0px 10px;
                padding: 10px 0px 10px 0px;
            }
        }

        /deep/.van-radio__icon {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .comments {
        margin: 20px 10px 20px 10px;
        
        /deep/.van-cell {
            color: red;
            font-size: 16px;
            border:0.5px dashed rgb(81, 126, 230);
        }

        .van-cell {
            // /deep/.van-field__label {
            //     padding: 0px 0px 0px 10px;
            //     margin: 0px 0px 0px 10px;
            // }

            /deep/.van-field__value {
                padding: 0px 2px 0px 0px;
                margin: 0px 2px 0px 0px;
            }
        }
    }
}

</style>
