import axios from "axios"
import store from "../../store/index"

const http = axios.create({
    // baseURL: "http://192.168.168.105:8081",
    baseURL:"/api",
    timeout: 30000
})

function getRequestToken() {
	if (store.state.accountType == null) {
		return null;
	}

	if ((store.state.accountType === "visitor")&&(store.state.visitor != null)) {
		return store.state.visitor.token;
	} else if ((store.state.accountType === "employee")&&(store.state.employee != null)) {
		return store.state.employee.token;
	}

	return null;
}

function logout() {
  store.state.loginFlag = null
  store.state.accountType = null
  store.state.visitor = null
  store.state.employee = null

  store.state.process = '-'
  store.state.finish = '-'
  store.state.finish = '-'
}

// Add a request interceptor
http.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = getRequestToken()
	
    if (token != null) {
      config.headers['authorization'] = token; // 替换
    }
	
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
http.interceptors.response.use(function (response) {
    // Do something with response data
    if (response.data.code === 401) {
      logout();
    }

    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });

export default http
