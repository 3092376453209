import http from "./config/http-configs";

export const confirmCheckOut = (applyId, visitorIds) => {
    return http.post(
        `/mobile/record/confirm?applyId=${applyId}&visitorIds=${visitorIds}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

//员工
export const getValidRecordsByEmployeeId = () => {
    return http.get('/mobile/record/list/employee/valid')
}

export const getInvalidRecordsByEmployeeId = (page) => {
    return http.post(
        '/mobile/record/list/employee/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

//访客
export const getValidRecordsByVisitorId = () => {
    return http.get('/mobile/record/list/visitor/valid')
}

export const getInvalidRecordsByVisitorId = (page) => {
    return http.post(
        '/mobile/record/list/visitor/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
