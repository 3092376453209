import Cookie from 'js-cookie'

class LocalCache {

    set(key, val) {
        localStorage.setItem(key, val)

        Cookie.set(key, val, {expires: 3650})
    }

    get(key) {
        let value = localStorage.getItem(key) != null ? localStorage.getItem(key) : Cookie.get(key);

        if (value != null) {
            this.set(key, value)
        }

        return value;
    }

    remove(key) {
        Cookie.remove(key)
        localStorage.removeItem(key)
    }

}

export default new LocalCache()
