import { render, staticRenderFns } from "./AccountLogin.vue?vue&type=template&id=c96d589c&scoped=true&"
import script from "./AccountLogin.vue?vue&type=script&lang=js&"
export * from "./AccountLogin.vue?vue&type=script&lang=js&"
import style0 from "./AccountLogin.vue?vue&type=style&index=0&id=c96d589c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c96d589c",
  null
  
)

export default component.exports