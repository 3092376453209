<template>
    <div>
        <van-dialog class="agr-dialog" v-model="showUserAgreement"  title="用户协议"
                style="overflow-y:auto" :show-confirm-button="false" :closeOnClickOverlay="true">
            <div class="main" v-html="userAgreement"/>
            
            <van-row class="bottom-button">
                <van-col span="4"></van-col>

                <van-col span="16">
                    <van-button size="small" type="warning" round block @click="showUserAgreement=false">确 认</van-button>
                </van-col>

                <van-col span="4"></van-col>
            </van-row>
        </van-dialog>

        <van-dialog class="agr-dialog" v-model="showSecretAgreement" title="隐私协议"
                style="overflow-y:auto" :show-confirm-button="false" :closeOnClickOverlay="true">
            <div class="main" v-html="secretAgreement"/>
        
            <van-row class="bottom-button">
                <van-col span="4"></van-col>

                <van-col span="16">
                    <van-button size="small" type="warning" round block @click="showSecretAgreement=false">确 认</van-button>
                </van-col>

                <van-col span="4"></van-col>
            </van-row>
        </van-dialog>

        <div class="main-logo">
            <van-image width="100" height="100" :src="logoUrl"/>
        </div>

        <div class="main-title">
            <h3>{{ mainTitle }}</h3>
        </div>

        <van-form @submit="loginSubmit">
            <van-field required v-model="form.tel" :rules="rules.telRule" center clearable type="tel"
                       placeholder="请输入 手机号"/>
            <van-field required v-model="form.sms" :rules="rules.smsRule" center clearable type="digit"
                       placeholder="请输入 短信验证码">
                <template #button>
                    <van-button :disabled="verifyBtnDis" size="small" type="primary" native-type="button"
                                style="width: 80px;" @click="getVerifyCode_()">{{ verifyBtnText }}
                    </van-button>
                </template>
            </van-field>

            <van-checkbox class="user-privacy" v-model="checked">为了保证您的个人隐私权益，请在同意按钮前认真阅读
                <a v-if="this.userAgreement != null" href="#" native-type="button" @click="showUserAgree()">《用户协议》</a>
                <a v-if="this.secretAgreement != null" href="#" native-type="button" @click="showSecretAgree()">、《隐私协议》</a>
            </van-checkbox>

            <van-row>
                <van-col span="16" offset="4">
                    <van-button type="info" native-type="submit" block>同意协议并登录</van-button>
                </van-col>
            </van-row>
        </van-form>
    </div>
</template>

<script>
import {telNumLogin} from '@/api/login-api'
import {getVerifyCode} from '@/api/sms-api'
import {getAgreementContent} from '@/api/setting-api'
import {getDownloadUrl} from "@/api/local-oss-api"
import {mapState, mapMutations} from 'vuex'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        //系统logo
        if (this.companyLogo != null) {
            this.logoUrl = getDownloadUrl() + this.companyLogo;
            console.log("logUrl => ", this.logoUrl)
        }

        //系统名称
        if ((this.systemName != null) && (this.systemName !== "")) {
            this.mainTitle = this.systemName;
        }

        //
        if (this.agrSettingInit === false) {
            getAgreementContent().then((data) => {
                this.printDebug("agreement content setting data => ", data)
                
                this.setAgrSettings(data)

                this.showUserAgreement = this.userAgrReadFlag;
                this.showSecretAgreement = this.secretAgrReadFlag;
            }).catch(_ => {
                this.printDebug("get agreement content setting data error!!!!!")
            })
        } else {
            this.showUserAgreement = this.userAgrReadFlag;
            this.showSecretAgreement = this.secretAgrReadFlag;
        }
    },

    data() {
        return {
            logoUrl: require('../../assets/logo.png'),
            mainTitle: "访客系统",

            form: {
                tel: "",
                sms: "",
            },

            verifyBtnDis: false,
            verifyBtnText: "获取验证码",
            intervalId: null,

            rules: {
                telRule: [
                    {required: true, tigger: 'onBlur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
                ],
                smsRule: [
                    {required: true,},
                    {pattern: /\d{6}$/, message: '验证码输入错误！'}
                ]
            },

            checked: "",

            //
            showUserAgreement: false,
            showSecretAgreement: false
        }
    },

    computed: {
        ...mapState(['openId', 'visitor', 'employee', 'systemName', 'companyLogo', 'agrSettingInit',
                    'userAgreement', 'secretAgreement', "userAgrReadFlag", "secretAgrReadFlag"])
    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setVisitor', 'setEmployee', 'setAgrSettings']),

        resetVerifyBtn() {
            clearInterval(this.intervalId)
            this.verifyBtnDis = false
            this.verifyBtnText = "获取验证码";
        },

        forbidVerifyBtn() {
            this.verifyBtnDis = true
            this.verifyBtnText = 120;

            let count = 119;

            this.intervalId = setInterval(() => {
                this.verifyBtnText = count;
                count--;

                if (count < 0) {
                    this.resetVerifyBtn()
                }
            }, 1000);
        },

        getVerifyCode_() {
            this.forbidVerifyBtn();

            getVerifyCode(this.form.tel, "账号登录").then(({data}) => {
                this.printDebug("data => ", data)
                if (data.code === 200) {
                } else {
                    this.resetVerifyBtn()
                    this.$toast.fail(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                this.resetVerifyBtn()
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        },
        
        loginSubmit() {
            if (!this.checked) {
                this.$toast.fail("请阅读并同意用户协议")
                return
            }
            
            this.printDebug("this.openId => ", this.openId);
            
            (async () => {
                let loginTel = {}

                loginTel.userTel = this.form.tel
                loginTel.smsCode = this.form.sms
                loginTel.wxOpenId = this.openId

                let login = await (await telNumLogin(loginTel)).data

                this.printDebug("login data => ", login)
                
                if (login.code === 800) {
                    this.$toast.fail(this.VERIFY_CODE_ERROR_MSG)
                    return
                }else if (login.code === 600) {
                    this.$toast.fail("访客禁止登录")
                    return
                } else if (login.code !== 200) {
                    this.$toast.fail("登录失败")
                    return
                }

                let account = login.data
                
                if (account.accType === "EMPLOYEE") {
                    this.setLoginFlag(true)
                    this.setAccountType(this.ACCOUNT_TYPE_EMPLOYEE)
                    this.setEmployee(account.employee)

                    this.$toast.success("登录成功")
                    this.$router.push({name: "home"})

                    return
                } else if (account.accType === "VISITOR") {
                    this.setLoginFlag(true)
                    this.setAccountType(this.ACCOUNT_TYPE_VISITOR)
                    this.setVisitor(account.visitor)

                    let setting = await getVisitorSettings();
                    
                    this.printDebug("setting = ", setting);

                    let result = this.verifyVisitorInfo(account.visitor, setting);

                    if (!result) {
                        this.$toast.success("请完善信息")
                        this.$router.push({name: "accountdetail", params: {source: "login"}})
                    } else {
                        this.$toast.success("登录成功")
                        this.$router.push({name: "home"})
                    }

                    return
                }

                this.$toast.fail("登录失败")
            })();
        },
        
        showUserAgree() {
            this.$router.push({name: "useragreement", params: {source: "login", type: 'user'}})
        },

        showSecretAgree() {
            this.$router.push({name: "useragreement", params: {source: "login", type: 'secret'}})
        }
    }
}
</script>

<style lang="less" scoped>
.van-dialog {
    top: 50%
}

.agr-dialog {
    width: 90vw;
    height: 90vh;
    
    /deep/.van-dialog__header {
        font-weight: 600;
        font-size: 18px;
    }

    .main {
        margin: 0px 10px 0px 10px;
    
        /deep/ ul {
            list-style:disc inside !important;
        }

        /deep/ ol {
            list-style:decimal inside !important;
        }
    }
    
    .bottom-button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 20px;
    }
}

.main-logo {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-title {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        text-align: center;
        color: rgb(81, 126, 230);
    }
}

.user-privacy {
    padding: 20px;
    size: 10px;
}
</style>