export const visitor_info_mixin_table = {
    data() {
        return {
            visitorSetting: {},

            visitorFieldValue: {},

            showVisitorPickerPopup: false,
            
            visitorPickerColumns: [],
            
            //访客信息字段
            visitorFormLabel: [
                {
                    prop: 'name',
                    label: '访客姓名',
                    show: true,
                    required: true,
                    rule: [{required: true}]
                },
                {
                    prop: 'tel',
                    label: '手机号码',
                    show: true,
                    required: true,
                    rule: [
                        {required: true, tigger: 'onBlur'},
                        {pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
                    ]
                },
                {
                    prop: 'cert_type',
                    label: '证件类型',
                    show: false,
                    required: false,
                    rule: [{required: false}]
                },
                {
                    prop: 'tag',
                    label: '护照号码',
                    show: false,
                    required: false,
                    rule: [{required: false}]
                },
                {
                    prop: 'ic',
                    label: '身份证号',
                    show: false,
                    required: false,
                    rule: [{required: false}]
                },
                {
                    prop: 'email',
                    label: '邮箱地址',
                    show: false,
                    required: false,
                    rule: [{required: false}]
                },
                {
                    prop: 'company',
                    label: '所在公司',
                    show: true,
                    required: true,
                    rule: [{required: true}]
                }
            ],

            //访客信息字段验证的正则表达式
            telNotNullRule: [
                {required: true, tigger: 'onBlur'},
                {pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
            ],
            
            telNullRule: [
                {required: false, tigger: 'onBlur'},
                {pattern: /^$|^\+?\d{10,13}$/, message: '手机号码格式错误！'}
            ],

            mailNotNullRule: [
                {required: true, tigger: 'onBlur'},
                {pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, message: '邮箱地址格式错误！'}
            ],

            mailNullRule: [
                {required: false, tigger: 'onBlur'},
                {pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$|^$/, message: '邮箱地址格式错误！'}
            ],

            icNotNullCardRule: [
                {required: true, tigger: 'onBlur'},
                {pattern: /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/, message: '身份证号码错误！'}
            ],

            //提供非空的正则表达式
            telRegex: /^1[3456789]\d{9}$/,
            emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            icCardRule: /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/
        }
    },

    methods: {
        resetVisitorLabel() {
            this.visitorFormLabel.forEach(item => {
                if ((item.prop === "cert_type") || (item.prop === "tag") 
                    || (item.prop === "ic") || (item.prop === "email")) {
                    item.show = false
                    item.required = false
                    item.rule = [{required: false}]
                }

                if (item.prop === "tel") {
                    item.show = true
                    item.required = true
                    item.rule = this.telNotNullRule
                }
            })
        },

        //邀约表单
        setInviteVisitorLabel(setting) {
            this.resetVisitorLabel();
            
            if ((setting === null)||(setting.value === "NONE")) {
                return
            }

            this.visitorFormLabel.forEach(item => {
                if (setting.value === "ID") {
                    if (item.prop === "ic") {
                        item.show = true
                        item.required = true
                        item.rule = this.icNotNullCardRule
                    }
                    
                    setting.depends.forEach(set => {
                        if ((set.name === "ID")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule

                            this.visitorFormLabel.forEach(item => {
                                if (item.prop === "tel") {
                                    item.show = true
                                    item.required = false
                                    item.rule = this.telNullRule
                                }
                            })
                        }
                    })
                } else if (setting.value === "PASSPORT") {
                    if (item.prop === "tag") {
                        item.show = true
                        item.required = true
                        item.rule = [{required: true}]
                    }

                    setting.depends.forEach(set => {
                        if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule

                            this.visitorFormLabel.forEach(item => {
                                if (item.prop === "tel") {
                                    item.show = true
                                    item.required = false
                                    item.rule = this.telNullRule
                                }
                            })
                        }
                    })
                } else if ((setting.value === "ALL")&&(item.prop === "cert_type")) {
                    item.show = true
                    item.required = true
                    item.rule = [{required: true}]

                    this.visitorSetting = setting
                }
            })
        },

        //预约表单
        setBookVisitorLabel(setting) {
            this.resetVisitorLabel();
            
            if ((setting === null)||(setting.value === "NONE")) {
                return
            }

            this.visitorFormLabel.forEach(item => {
                if (setting.value === "ID") {
                    if (item.prop === "ic") {
                        item.show = true
                        item.required = true
                        item.rule = this.icNotNullCardRule
                    }
                    
                    setting.depends.forEach(set => {
                        if ((set.name === "ID")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule
                        }
                    })
                } else if (setting.value === "PASSPORT") {
                    if (item.prop === "tag") {
                        item.show = true
                        item.required = true
                        item.rule = [{required: true}]
                    }

                    setting.depends.forEach(set => {
                        if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule
                        }
                    })
                } else if ((setting.value === "ALL")&&(item.prop === "cert_type")) {
                    item.show = true
                    item.required = true
                    item.rule = [{required: true}]

                    this.visitorSetting = setting
                }
            })
        },
        
        //访客信息显示表单
        setShowVisitorLabel(visitor, setting) {
            this.resetVisitorLabel();
            
            if ((setting === null) || (setting.value === "NONE")) {
                return
            }

            this.visitorFormLabel.forEach(item => {
                if (setting.value === "ID") {
                    if (item.prop === "ic") {
                        item.show = true
                        item.required = true
                        item.rule = this.icNotNullCardRule
                    }
                    
                    setting.depends.forEach(set => {
                        if ((set.name === "ID")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule
                        }
                    })
                } else if (setting.value === "PASSPORT") {
                    if (item.prop === "tag") {
                        item.show = true
                        item.required = true
                        item.rule = [{required: true}]
                    }

                    setting.depends.forEach(set => {
                        if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(item.prop === "email")) {
                            item.show = true
                            item.required = true
                            item.rule = this.mailNotNullRule
                        }
                    })
                } else if (setting.value === "ALL") {
                    if ((visitor.ic !== null)&&(visitor.ic !== "")&&(item.prop === "ic")) {
                        item.show = true
                        item.required = true
                        item.rule = this.icNotNullCardRule
                    }
                    
                    if ((visitor.tag !== null)&&(visitor.tag !== "")&&(item.prop === "tag")) {
                        item.show = true
                        item.required = true
                        item.rule = [{required: true}]
                    }

                    if ((visitor.email !== null)&&(visitor.email !== "")&&(item.prop === "email")) {
                        item.show = true
                        item.required = true
                        item.rule = this.mailNotNullRule
                    }
                }
            })
        },

        //
        onInviteVisitorPickerConfirm(value, form) {
            if (this.visitorFieldValue.prop === "cert_type") {
                this.visitorFormLabel.forEach(item => {
                    if (value === "身份证") {
                        if (item.prop === "ic") {
                            item.show = true
                            item.required = true
                            item.rule = this.icNotNullCardRule
                        }

                        if (item.prop === "tag") {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }

                        if (item.prop === "tel") {
                            item.show = true
                            item.required = true
                            item.rule = this.telNotNullRule
                        }

                        if ((item.prop === "email")) {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }
                        
                        this.visitorSetting.depends.forEach(set => {
                            if ((set.name === "ID")&&(set.dependField === "email")&&(item.prop === "email")) {
                                item.show = true
                                item.required = true
                                item.rule = this.mailNotNullRule

                                this.visitorFormLabel.forEach(item => {
                                    if (item.prop === "tel") {
                                        item.show = true
                                        item.required = false
                                        item.rule = this.telNullRule
                                    }
                                })
                            }
                        })
                    } else if (value === "护照") {
                        if (item.prop === "tag") {
                            item.show = true
                            item.required = true
                            item.rule = [{required: true}]
                        }

                        if (item.prop === "ic") {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }

                        if (item.prop === "tel") {
                            item.show = true
                            item.required = true
                            item.rule = this.telNotNullRule
                        }

                        if ((item.prop === "email")) {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }
                        
                        this.visitorSetting.depends.forEach(set => {
                            if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(item.prop === "email")) {
                                item.show = true
                                item.required = true
                                item.rule = this.mailNotNullRule

                                this.visitorFormLabel.forEach(item => {
                                    if (item.prop === "tel") {
                                        item.show = true
                                        item.required = false
                                        item.rule = this.telNullRule
                                    }
                                })
                            }
                        })
                    }
                })
            }
            
            form[this.visitorFieldValue.prop] = value

            this.showVisitorPickerPopup = false;
        },

        onBookVisitorPickerConfirm(value, form) {
            if (this.visitorFieldValue.prop === "cert_type") {
                this.visitorFormLabel.forEach(item => {
                    if (value === "身份证") {
                        if (item.prop === "ic") {
                            item.show = true
                            item.required = true
                            item.rule = this.icNotNullCardRule
                        }

                        if (item.prop === "tag") {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }

                        if ((item.prop === "email")) {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }
                        
                        this.visitorSetting.depends.forEach(set => {
                            if ((set.name === "ID")&&(set.dependField === "email")&&(item.prop === "email")) {
                                item.show = true
                                item.required = true
                                item.rule = this.mailNotNullRule
                            }
                        })
                    } else if (value === "护照") {
                        if (item.prop === "tag") {
                            item.show = true
                            item.required = true
                            item.rule = [{required: true}]
                        }

                        if (item.prop === "ic") {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }

                        if ((item.prop === "email")) {
                            item.show = false
                            item.required = false
                            item.rule = [{required: false}]
                        }
                        
                        this.visitorSetting.depends.forEach(set => {
                            if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(item.prop === "email")) {
                                item.show = true
                                item.required = true
                                item.rule = this.mailNotNullRule
                            }
                        })
                    }
                })
            }
            
            form[this.visitorFieldValue.prop] = value

            this.showVisitorPickerPopup = false;
        },
        
        //验证访客信息是否符合设置
        verifyVisitorInfo(visitor, setting) {
            if ((visitor.tel == null) || (visitor.name == null) || (visitor.company == null)
                ||(visitor.tel == "") || (visitor.name == "") || (visitor.company == ""))  {
                return false;
            }

            if (setting !== null) {
                if (setting.value === "ID") {
                    if ((visitor.ic === null)||(visitor.ic === "")) {
                        return false;
                    }

                    for (const index in setting.depends) {
                        let set = setting.depends[index]
                        if ((set.name === "ID")&&(set.dependField === "email")&&(visitor.email === null)) {
                            return false;
                        }
                    }
                } else if (setting.value === "PASSPORT") {
                    if ((visitor.tag === null)||(visitor.tag === "")) {
                        return false;
                    }

                    for (const index in setting.depends) {
                        let set = setting.depends[index]
                        if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(visitor.email === null)) {
                            return false;
                        }
                    }
                } else if (setting.value === "ALL") {
                    if (((visitor.ic === null)||(visitor.ic === ""))&&((visitor.tag === null)||(visitor.tag === ""))) {
                        return false;
                    }

                    if (visitor.ic !== null) {
                        for (const index in setting.depends) {
                            let set = setting.depends[index]
                            if ((set.name === "ID")&&(set.dependField === "email")&&(visitor.email === null)) {
                                return false;
                            }
                        }
                    }

                    if (visitor.tag !== null) {
                        for (const index in setting.depends) {
                            let set = setting.depends[index]
                            if ((set.name === "PASSPORT")&&(set.dependField === "email")&&(visitor.email === null)) {
                                return false;
                            }
                        }
                    }
                }
            }

            return true;
        }
    }
}
