<template>
    <div class="manage">
        <van-popup v-model="showVisitorPickerPopup" position="bottom">
            <van-picker show-toolbar :columns="visitorPickerColumns"
                @confirm="onVisitorPickerConfirm" @cancel="showVisitorPickerPopup = false"/>
        </van-popup>
        
        <van-dialog class="dialog" v-model="showDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
			<van-form ref="visitorFromRef" @submit="submitAdd">
                <div class="dialog-header">
                    添加访客信息
                </div>
                
                <div v-for="(item, index) in visitorFormLabel" :key="index">
                    <van-field v-if="item.show === true" v-model="form[item.prop]" :required="item.required" :rules="item.rule"
                            :name="item.label" :label="item.label" :placeholder="'请输入 ' + item.label" colon
                            @click="visitorFormFieldClick(item)">
                    </van-field>
                </div>
                
                <van-row class="dialog-footer">
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="button" @click="cancelAdd()">取消</van-button>
					</van-col>
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="submit">确认</van-button>
					</van-col>
				</van-row>
            </van-form>
		</van-dialog>
        
        <div class="area">
            <van-form v-for="(item, index) in visitorList" :key="index">
                <div @click="itemClick(item)">
                    <van-field v-model="item.tel" readonly label-width="70px" label="手机号码:"/>
                    <van-field v-model="item.infos" readonly label-width="70px"  label="访客信息:"/>
                </div>
            </van-form>
        </div>
        
        <div class="bottom">
            <van-row>
                <van-col span="24">
                    <van-tabbar style="height: 40px; margin-top:20px" fixed placeholder safe-area-inset-bottom>
                        <van-tabbar-item @click="addPeople()" style="color: #F56C6C; font-size: 16px !important;">
                            新    增
                        </van-tabbar-item>
                    </van-tabbar>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { addCompanyVisitor } from '@/api/visitor-api'
import {addUsualVisitor, updateUsualVisitor, getUsualVisitor} from '@/api/usual-api'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.submit_info = this.$route.params.submit_info
        this.extern_info = this.$route.params.extern_info
        
        this.printDebug("this.$route.params => ", this.$route.params)
        
        getUsualVisitor().then(({data}) => {
            this.visitorList = data.data == null ? [] : data.data
            
            this.visitorList.forEach((item, index) => {
                this.visitorList[index].infos = item.name + " | " + item.company
            })

            this.printDebug("visitorList => ", this.visitorList)
        }).catch(_ => {
            // this.$toast.fail(this.NETWORK_ERROR_MSG)
        })
    },

    data() {
        return {
            source : null,
            type: null,
            submit_info : null,
            extern_info: null,
            
            showDialog : false,
            
            visitorList : [],
            
            form: {
                name: null,
                tel: null,
                company: null,
                tag: null,
                ic: null,
                email: null
            }
        }
    },

    computed: {
        ...mapState(['employee'])
    },

    methods: {
        visitorFormFieldClick(item) {
            this.visitorFieldValue = item;

            if (item.prop === "cert_type") {
                document.activeElement.blur()

                this.visitorPickerColumns = ["护照", "身份证"]

                this.showVisitorPickerPopup = true
            }
        },

        onVisitorPickerConfirm(value) {
            this.onInviteVisitorPickerConfirm(value, this.form);
        },

        addPeople() {
            (async () => {
                let setting = await getVisitorSettings();

                this.printDebug("setting = ", setting);

                if (setting != null) {
                    this.setInviteVisitorLabel(setting);
                }
                
                //
                this.form = {}

                this.showDialog = true

                //加上判断，第一次 dialog 弹出，form 表单还没有初始化。
                if (this.$refs.visitorFromRef != null) {
                    this.$refs.visitorFromRef.resetValidation();
                }
            })();
        },
        
        cancelAdd() {
            this.form = {}
            this.showDialog = false
        },

        submitAdd() {
            let visitor = {};
            
            (async () => {
                if ((this.form.tel !== null)&&(!this.telRegex.test(this.form.tel))) {
                    this.form.tel = "888********";
                }

                if ((this.form.email !== null)&&(!this.emailRegex.test(this.form.email))) {
                    this.form.email = null;
                }

                if ((this.form.name.trim() === "")||(this.form.company.trim() === "")) {
                    this.$toast.fail("请输入合法的姓名和公司")
                    return
                }

                let data = (await addCompanyVisitor(this.form)).data;

                this.printDebug("login data => ", data)

                if (data.code !== 200) {
                    this.$toast.fail("添加访客失败");
                    return
                }

                visitor = data.data;
                visitor.infos = visitor.name + " | " + visitor.company;
                
                let old_length = this.visitorList.length;
                this.visitorList = this.visitorList.filter(record => record.id !== visitor.id);

                if (this.visitorList.length === old_length) {
                    this.printDebug("no exist")

                    addUsualVisitor(visitor.code).then(({data}) => {
                        this.printDebug("usualVisitor data => ", data)
                        this.visitorList.unshift(visitor)
                        this.showDialog = false

                        if (visitor.exist === "EXIST") {
                            this.$toast.success("访客已存在")
                        }
                    }).catch(_ => {
                        this.$toast.fail(this.NETWORK_ERROR_MSG)
                    })
                } else {
                    this.printDebug("exist")

                    updateUsualVisitor(visitor.code).then(({data}) => {
                        this.printDebug("updateUsualVisitor data => ", data)
                        this.visitorList.unshift(visitor)
                        this.showDialog = false

                        if (visitor.exist === "EXIST") {
                            this.$toast.success("访客已存在")
                        }
                    }).catch(_ => {
                        this.$toast.fail(this.NETWORK_ERROR_MSG)
                    })
                }
            })();
        },

        itemClick(item) {
            (async () => {
                let setting = await getVisitorSettings();
                let result = this.verifyVisitorInfo(item, setting);
                if (!result) {
                    this.$toast.success("需要访客完善信息")
                    return
                }

                this.$router.push({ name : this.source, 
                                params:{
                                    'source' : 'addvisitor', 
                                    'value' : item,
                                    "type": this.type,
                                    "submit_info" : this.submit_info,
                                    "extern_info": this.extern_info
                                }})
            })()
        }
    }
}
</script>

<style lang="less" scoped>

.dialog {
    .dialog-header {
		display: flex;
		justify-content: center;
		color: green;
	}

	.dialog-footer {
		margin-bottom: 10px;
        margin-top: 20px;
	}
}

.manage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;

    .search {
        flex: 0 0 auto;
    }

    .area {
        flex: 1 0 auto;

        .van-form {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: 80px;

            margin: 10px 10px 0px 10px;

            border-radius: 15px;
            border:0.5px solid rgb(81, 126, 230);
            box-shadow: 0 0 15px #606266;

            .van-cell {
                padding: 0px 0px 0px 10px;
            }

            div {
                margin: 0px;
                padding: 0px;
            }
        }
        
        /deep/.van-field__label {
            color: #67C23A;
        }
    }
    
    .bottom {
        flex: 0 0 auto;
    }
}
    
</style>