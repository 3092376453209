import http from "./config/http-configs";

export const telNumLogin = (login) => {
    return http.post(
        '/mobile/login/tel',
        login,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const openIdLogin = (openId) => {
    return http.get(`/mobile/login/wx-openid/${openId}`)
}

export const accountLogout = (logout) => {
    return http.post(
        '/mobile/login/exit',
        logout,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
